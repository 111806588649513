/**
 * Zybach.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ChemigationPermitDetailedDto } from '../model/chemigation-permit-detailed-dto';
import { SensorChartDataDto } from '../model/sensor-chart-data-dto';
import { WaterLevelInspectionSummaryDto } from '../model/water-level-inspection-summary-dto';
import { WaterQualityInspectionSummaryDto } from '../model/water-quality-inspection-summary-dto';
import { WellContactInfoDto } from '../model/well-contact-info-dto';
import { WellDetailDto } from '../model/well-detail-dto';
import { WellNewDto } from '../model/well-new-dto';
import { WellParticipationDto } from '../model/well-participation-dto';
import { WellParticipationInfoDto } from '../model/well-participation-info-dto';
import { WellPumpingSummaryDto } from '../model/well-pumping-summary-dto';
import { WellRegistrationIDDto } from '../model/well-registration-id-dto';
import { WellSimpleDto } from '../model/well-simple-dto';
import { WellUseDto } from '../model/well-use-dto';
import { WellWaterQualityInspectionSummaryDto } from '../model/well-water-quality-inspection-summary-dto';
import { WellWithSensorSimpleDto } from '../model/well-with-sensor-simple-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class WellService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellParticipationsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WellParticipationDto>>;
    public wellParticipationsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellParticipationDto>>>;
    public wellParticipationsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellParticipationDto>>>;
    public wellParticipationsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellParticipationDto>>(`${this.basePath}/wellParticipations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellUsesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WellUseDto>>;
    public wellUsesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellUseDto>>>;
    public wellUsesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellUseDto>>>;
    public wellUsesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellUseDto>>(`${this.basePath}/wellUses`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WellWithSensorSimpleDto>>;
    public wellsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellWithSensorSimpleDto>>>;
    public wellsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellWithSensorSimpleDto>>>;
    public wellsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellWithSensorSimpleDto>>(`${this.basePath}/wells`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsInspectionSummariesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WellWaterQualityInspectionSummaryDto>>;
    public wellsInspectionSummariesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellWaterQualityInspectionSummaryDto>>>;
    public wellsInspectionSummariesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellWaterQualityInspectionSummaryDto>>>;
    public wellsInspectionSummariesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellWaterQualityInspectionSummaryDto>>(`${this.basePath}/wells/inspectionSummaries`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellNewDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsNewPost(wellNewDto?: WellNewDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsNewPost(wellNewDto?: WellNewDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsNewPost(wellNewDto?: WellNewDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsNewPost(wellNewDto?: WellNewDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/wells/new`,
            wellNewDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsSearchWellRegistrationIDGet(wellRegistrationID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public wellsSearchWellRegistrationIDGet(wellRegistrationID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public wellsSearchWellRegistrationIDGet(wellRegistrationID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public wellsSearchWellRegistrationIDGet(wellRegistrationID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellsSearchWellRegistrationIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/wells/search/${encodeURIComponent(String(wellRegistrationID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsSearchWellRegistrationIDHasInspectionTypeGet(wellRegistrationID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public wellsSearchWellRegistrationIDHasInspectionTypeGet(wellRegistrationID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public wellsSearchWellRegistrationIDHasInspectionTypeGet(wellRegistrationID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public wellsSearchWellRegistrationIDHasInspectionTypeGet(wellRegistrationID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellsSearchWellRegistrationIDHasInspectionTypeGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/wells/search/${encodeURIComponent(String(wellRegistrationID))}/hasInspectionType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsSearchWellRegistrationIDRequiresChemigationGet(wellRegistrationID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public wellsSearchWellRegistrationIDRequiresChemigationGet(wellRegistrationID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public wellsSearchWellRegistrationIDRequiresChemigationGet(wellRegistrationID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public wellsSearchWellRegistrationIDRequiresChemigationGet(wellRegistrationID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellsSearchWellRegistrationIDRequiresChemigationGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/wells/search/${encodeURIComponent(String(wellRegistrationID))}/requiresChemigation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDChemigationPermitsGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ChemigationPermitDetailedDto>>;
    public wellsWellIDChemigationPermitsGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChemigationPermitDetailedDto>>>;
    public wellsWellIDChemigationPermitsGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChemigationPermitDetailedDto>>>;
    public wellsWellIDChemigationPermitsGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDChemigationPermitsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ChemigationPermitDetailedDto>>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/chemigationPermits`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDContactInfoGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellContactInfoDto>;
    public wellsWellIDContactInfoGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellContactInfoDto>>;
    public wellsWellIDContactInfoGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellContactInfoDto>>;
    public wellsWellIDContactInfoGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDContactInfoGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellContactInfoDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/contactInfo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param wellContactInfoDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDContactInfoPut(wellID: number, wellContactInfoDto?: WellContactInfoDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDContactInfoPut(wellID: number, wellContactInfoDto?: WellContactInfoDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDContactInfoPut(wellID: number, wellContactInfoDto?: WellContactInfoDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDContactInfoPut(wellID: number, wellContactInfoDto?: WellContactInfoDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDContactInfoPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/contactInfo`,
            wellContactInfoDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param wellRegistrationIDDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDEditRegistrationIDPut(wellID: number, wellRegistrationIDDto?: WellRegistrationIDDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDEditRegistrationIDPut(wellID: number, wellRegistrationIDDto?: WellRegistrationIDDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDEditRegistrationIDPut(wellID: number, wellRegistrationIDDto?: WellRegistrationIDDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDEditRegistrationIDPut(wellID: number, wellRegistrationIDDto?: WellRegistrationIDDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDEditRegistrationIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/editRegistrationID`,
            wellRegistrationIDDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDFlowMeterSensorsGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<SensorChartDataDto>;
    public wellsWellIDFlowMeterSensorsGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorChartDataDto>>;
    public wellsWellIDFlowMeterSensorsGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorChartDataDto>>;
    public wellsWellIDFlowMeterSensorsGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDFlowMeterSensorsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SensorChartDataDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/flowMeterSensors`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellDetailDto>;
    public wellsWellIDGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellDetailDto>>;
    public wellsWellIDGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellDetailDto>>;
    public wellsWellIDGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellDetailDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDNitrateChartSpecGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDNitrateChartSpecGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDNitrateChartSpecGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDNitrateChartSpecGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDNitrateChartSpecGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/nitrateChartSpec`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDParticipationInfoGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellParticipationInfoDto>;
    public wellsWellIDParticipationInfoGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellParticipationInfoDto>>;
    public wellsWellIDParticipationInfoGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellParticipationInfoDto>>;
    public wellsWellIDParticipationInfoGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDParticipationInfoGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellParticipationInfoDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/participationInfo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param wellParticipationInfoDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDParticipationInfoPut(wellID: number, wellParticipationInfoDto?: WellParticipationInfoDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDParticipationInfoPut(wellID: number, wellParticipationInfoDto?: WellParticipationInfoDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDParticipationInfoPut(wellID: number, wellParticipationInfoDto?: WellParticipationInfoDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDParticipationInfoPut(wellID: number, wellParticipationInfoDto?: WellParticipationInfoDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDParticipationInfoPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/participationInfo`,
            wellParticipationInfoDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDSimpleDtoGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellSimpleDto>;
    public wellsWellIDSimpleDtoGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellSimpleDto>>;
    public wellsWellIDSimpleDtoGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellSimpleDto>>;
    public wellsWellIDSimpleDtoGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDSimpleDtoGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellSimpleDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/simpleDto`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDSyncPumpedVolumeFromAgHubPost(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDSyncPumpedVolumeFromAgHubPost(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDSyncPumpedVolumeFromAgHubPost(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDSyncPumpedVolumeFromAgHubPost(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDSyncPumpedVolumeFromAgHubPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/syncPumpedVolumeFromAgHub`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDWaterLevelChartSpecGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellsWellIDWaterLevelChartSpecGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellsWellIDWaterLevelChartSpecGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellsWellIDWaterLevelChartSpecGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDWaterLevelChartSpecGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/waterLevelChartSpec`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDWaterLevelInspectionsGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterLevelInspectionSummaryDto>>;
    public wellsWellIDWaterLevelInspectionsGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterLevelInspectionSummaryDto>>>;
    public wellsWellIDWaterLevelInspectionsGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterLevelInspectionSummaryDto>>>;
    public wellsWellIDWaterLevelInspectionsGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDWaterLevelInspectionsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterLevelInspectionSummaryDto>>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/waterLevelInspections`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDWaterLevelSensorsGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<SensorChartDataDto>;
    public wellsWellIDWaterLevelSensorsGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorChartDataDto>>;
    public wellsWellIDWaterLevelSensorsGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorChartDataDto>>;
    public wellsWellIDWaterLevelSensorsGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDWaterLevelSensorsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SensorChartDataDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/waterLevelSensors`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDWaterQualityInspectionsGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterQualityInspectionSummaryDto>>;
    public wellsWellIDWaterQualityInspectionsGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterQualityInspectionSummaryDto>>>;
    public wellsWellIDWaterQualityInspectionsGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterQualityInspectionSummaryDto>>>;
    public wellsWellIDWaterQualityInspectionsGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDWaterQualityInspectionsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterQualityInspectionSummaryDto>>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/waterQualityInspections`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellPumpingSummaryStartDateEndDateGet(startDate: string, endDate: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WellPumpingSummaryDto>>;
    public wellsWellPumpingSummaryStartDateEndDateGet(startDate: string, endDate: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellPumpingSummaryDto>>>;
    public wellsWellPumpingSummaryStartDateEndDateGet(startDate: string, endDate: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellPumpingSummaryDto>>>;
    public wellsWellPumpingSummaryStartDateEndDateGet(startDate: string, endDate: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling wellsWellPumpingSummaryStartDateEndDateGet.');
        }

        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling wellsWellPumpingSummaryStartDateEndDateGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellPumpingSummaryDto>>(`${this.basePath}/wells/wellPumpingSummary/${encodeURIComponent(String(startDate))}/${encodeURIComponent(String(endDate))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
