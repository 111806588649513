<div class="row col-12 mt-2">
    <div class="col-12 col-md-6">
        <div class="row">
            <div class="row mt-2">
                <div class="col">
                    <div class="card" *ngIf="well">
                        <div class="card-header">
                            Basics
                            <div class="float-end">
                                <a
                                    class="btn btn-zybach btn-sm float-end"
                                    [ngClass]="{ disabled: isInAgHub() || isUserReadOnly() }"
                                    routerLink="/wells/{{ well.WellID }}/edit-registration-id">
                                    <span class="fas fa-edit"></span> Edit Reg. ID
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-sm-6 label">
                                    <strong> <field-definition [fieldDefinitionType]="'WellRegistrationNumber'"> </field-definition>: </strong>
                                </div>
                                <div class="col-12 col-sm-6">{{ well.WellRegistrationID }}</div>
                                <div class="col-12 col-sm-6 label"><strong>In AgHub? </strong></div>
                                <div class="col-12 col-sm-6">{{ well.InAgHub ? "Yes" : "No" }}</div>
                                <div class="col-12 col-sm-6 label">
                                    <strong> <field-definition [fieldDefinitionType]="'IrrigationUnitID'"> </field-definition>: </strong>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div *ngIf="well.AgHubIrrigationUnitID; then hasIU; else noIU"></div>
                                    <ng-template #hasIU>
                                        <a routerLink="/irrigation-units/{{ well.AgHubIrrigationUnitID }}">{{ well.WellTPID }}</a>
                                    </ng-template>
                                    <ng-template #noIU>
                                        <em>No Associated Irrigation Unit</em>
                                    </ng-template>
                                </div>
                                <div class="col-12 col-sm-6 label">
                                    <strong> <field-definition [fieldDefinitionType]="'AgHubRegisteredUser'"> </field-definition>: </strong>
                                </div>
                                <div class="col-12 col-sm-6">{{ well.AgHubRegisteredUser }}</div>
                                <div class="col-12 col-sm-6 label">
                                    <strong> <field-definition [fieldDefinitionType]="'WellFieldName'"> </field-definition>: </strong>
                                </div>
                                <div class="col-12 col-sm-6">{{ well.FieldName }}</div>
                                <div class="col-12 col-sm-6 label">
                                    <strong>{{ getDataSourcesLabel() }}</strong>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <p *ngFor="let sensorType of getSensorTypes()" class="m-0">
                                        {{ sensorType }}
                                    </p>
                                    <p *ngIf="well.hasElectricalData" class="m-0">Electrical Usage</p>
                                </div>
                                <div class="col-12 col-sm-6 label">
                                    <strong> <field-definition [fieldDefinitionType]="'SensorLastReadingDate'"> </field-definition>: </strong>
                                </div>
                                <div class="col-12 col-sm-6">{{ getLastReadingDate() }}</div>
                                <div class="col-12 col-sm-6 label">
                                    <strong> <field-definition [fieldDefinitionType]="'WellIrrigatedAcres'"> </field-definition>: </strong>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <ng-container *ngIf="displayIrrigatedAcres(); else irrigatedAcresPerYearEmpty">
                                        <p *ngFor="let result of getWellIrrigatedAcresPerYear()" class="m-0">{{ result.Acres }} ac in {{ result.Year }}</p>
                                    </ng-container>
                                    <ng-template #irrigatedAcresPerYearEmpty>
                                        <em>Irrigated Acres are not available for this parcel</em>
                                    </ng-template>
                                </div>
                                <div class="col-12 col-sm-6 label">
                                    <strong>Pump Rate:</strong>
                                </div>
                                <div class="col-12 col-sm-6">{{ well.PumpingRateGallonsPerMinute }} GPM</div>
                                <div class="col-12 col-sm-6 label">
                                    <strong>Pump Rate Source:</strong>
                                </div>
                                <div class="col-12 col-sm-6">{{ well.PumpingRateSource ?? "None" }}</div>
                                <div class="col-12 col-sm-6 label">
                                    <strong>Well Groups:</strong>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div *ngFor="let wellGroup of well.WellGroups">
                                        <a routerLink="/well-groups/{{ wellGroup.WellGroupID }}">{{ wellGroup.WellGroupName }}</a>
                                    </div>
                                    <div *ngIf="well.WellGroups?.length == 0">None</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <div class="card" *ngIf="well">
                        <div class="card-header">
                            Contact Information
                            <div class="float-end">
                                <a class="btn btn-zybach btn-sm float-end" [ngClass]="{ disabled: isUserReadOnly() }" routerLink="/wells/{{ well.WellID }}/edit-contact">
                                    <span class="fas fa-edit"></span> Edit
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-sm-6 label">
                                    <strong> <field-definition [fieldDefinitionType]="'WellOwnerName'"> </field-definition>: </strong>
                                </div>
                                <div class="col-12 col-sm-6" [innerHtml]="getWellOwner()"></div>
                                <div class="col-12 col-sm-6 label"><strong>Additional Contact: </strong></div>
                                <div class="col-12 col-sm-6" [innerHtml]="getWellAdditionalContact()"></div>
                                <div class="col-12 col-sm-6 label"><strong>Township, Range, Section:</strong></div>
                                <div class="col-12 col-sm-6">{{ well.TownshipRangeSection }}</div>
                                <div class="col-12 col-sm-6 label"><strong>County: </strong></div>
                                <div class="col-12 col-sm-6">{{ well.County }}</div>
                                <div class="col-12 col-sm-6 label">
                                    <strong> <field-definition [fieldDefinitionType]="'WellNickname'"> </field-definition>: </strong>
                                </div>
                                <div class="col-12 col-sm-6">{{ well.WellNickname }}</div>
                                <div class="col-12 col-sm-6 label"><strong>Notes: </strong></div>
                                <div class="col-12 col-sm-6">{{ well.Notes }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="row mt-2">
            <div class="col">
                <div class="card">
                    <div class="card-header">Location</div>
                    <div class="card-body">
                        <div #mapContainer id="mapContainer" style="height: 400px"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <div class="card" *ngIf="well">
                    <div class="card-header">
                        Program Participation
                        <div class="float-end">
                            <a class="btn btn-zybach btn-sm float-end" [ngClass]="{ disabled: isUserReadOnly() }" routerLink="/wells/{{ well.WellID }}/edit-participation">
                                <span class="fas fa-edit"></span> Edit
                            </a>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-7 label">
                                        <strong> <field-definition [fieldDefinitionType]="'WellProgramParticipation'"> </field-definition>: </strong>
                                    </div>
                                    <div class="col-12 col-sm-5">{{ well.WellParticipationName }}</div>
                                    <div class="col-12 col-sm-7 label"><strong>Well Use: </strong></div>
                                    <div class="col-12 col-sm-5">{{ well.WellUseName }}</div>
                                    <div class="col-12 col-sm-7 label">
                                        <strong>
                                            <field-definition [fieldDefinitionType]="'WellChemigationInspectionParticipation'"> </field-definition>
                                        </strong>
                                    </div>
                                    <div class="col-12 col-sm-5">{{ well.RequiresChemigation ? "Yes" : "No" }}</div>
                                    <div class="col-12 col-sm-7 label">
                                        <strong>
                                            <field-definition [fieldDefinitionType]="'WellWaterLevelInspectionParticipation'"> </field-definition>
                                        </strong>
                                    </div>
                                    <div class="col-12 col-sm-5">{{ well.RequiresWaterLevelInspection ? "Yes" : "No" }}</div>
                                    <div class="col-12 col-sm-7 label">
                                        <strong>
                                            <field-definition [fieldDefinitionType]="'WellWaterQualityInspectionParticipation'"> </field-definition>
                                        </strong>
                                    </div>
                                    <div class="col-12 col-sm-5">{{ well.WaterQualityInspectionTypes }}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-7 label"><strong>Clearinghouse ID: </strong></div>
                                    <div class="col-12 col-sm-5">{{ well.Clearinghouse }}</div>
                                    <div class="col-12 col-sm-7 label"><strong>Site Name: </strong></div>
                                    <div class="col-12 col-sm-5">{{ well.SiteName }}</div>
                                    <div class="col-12 col-sm-7 label"><strong>Site Number: </strong></div>
                                    <div class="col-12 col-sm-5">{{ well.SiteNumber }}</div>
                                    <div class="col-12 col-sm-7 label"><strong>Replacement Well? </strong></div>
                                    <div class="col-12 col-sm-5">{{ well.IsReplacement ? "Yes" : "No" }}</div>
                                    <div class="col-12 col-sm-7 label"><strong>Well Depth: </strong></div>
                                    <div class="col-12 col-sm-5">{{ well.WellDepth }}</div>
                                    <div class="col-12 col-sm-7 label"><strong>Screen Interval: </strong></div>
                                    <div class="col-12 col-sm-5">{{ well.ScreenInterval }}</div>
                                    <div class="col-12 col-sm-7 label"><strong>Screen Depth: </strong></div>
                                    <div class="col-12 col-sm-5">{{ well.ScreenDepth }}</div>
                                    <div class="col-12 col-sm-7 label"><strong>Page #: </strong></div>
                                    <div class="col-12 col-sm-5">{{ well.PageNumber }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
